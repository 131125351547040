import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer'
import ImageMeta from '../components/ImageMeta'
import SeamlessIframe from '../components/SeamlessIframe'
import { telephoneStrip } from '../utils/utils'
import TheaterVideo from '../components/Theater/TheaterVideo'

const DentalImplantsTodayPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <Layout path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <div className="dental-implants-page">
        <div className="columns dental-implants-top">
          <div className="column is-4 is-hidden-tablet-only"></div>

          <div className="column">
            <div className="columns dental-implants-top-text">
              <div className="column is-2" />
              <div>
                <h1>{post.heading}</h1>
                <p>{post.blurb}</p>
              </div>
              <div className="column is-2" />
            </div>
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
              controls
              playing
              overVideo
              iconOnly
              language={'en'}
            >
              <ImageMeta
                publicId={post.imageId}
                cloudName="nuvolum"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
                className="review-row-photo"
              />
            </TheaterVideo>
          </div>
          <div className="column is-4 is-hidden-tablet-only"></div>
        </div>

        <div className="columns">
          <div className="column is-3"></div>
          <div className="column is-8 contact-address-container">
            <div className="sticky-scroll columns">
              <div className="column is-4 is-hidden-tablet-only" />
              <div>
                <h2>{post.formHeading}</h2>
                {/* <p>{post.blurb}</p> */}
              </div>
              <div className="column is-2" />
            </div>
          </div>
          <div className="column contact-form-container">
            <SeamlessIframe src={post.formSrc} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DentalImplantsTodayPage

export const pageQuery = graphql`
  query dentalImplantsToday($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        addressHeading
        formHeading
        heading
        blurb
        formSrc
        youtube
        imageId
      }
    }
  }
`
